import React, { useEffect, useState } from "react";
import { PropTypes } from "prop-types";
import { isEqual, reduce } from "lodash";
import { Collapse } from ".";
import { Card, Grid, IconButton, Typography } from "@material-ui/core";
import { styled } from "@material-ui/styles";
import { getDevMode } from "../../api/localStorage";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

function isDev() {
	return window.location.origin.indexOf("localhost") !== -1 && getDevMode();
}

const DebugScreen = styled(Grid)({
	position: "fixed",
	top: 0,
	left: 0,
	width: "400px",
	maxHeight: "100vh",
	zIndex: 9999,
	opacity: 0.8,
	"&:hover": {
		opacity: 1,
	},
});

function Code(props) {
	return (
		<pre
			style={{
				width: "100%",
				maxHeight: "30vh",
				overflowY: "scroll",
				overflowX: "hidden",
				fontSize: ".85rem",
				fontFamily: "consolas",
				padding: ".25rem .5rem",
				margin: "0",
			}}
		>
			{props.children}
		</pre>
	);
}

function Debug({ formik }) {
	const { t } = useTranslation();

	const { initialValues, values, ...rest } = formik;
	const [visible, setVisible] = useState(true);

	useEffect(() => {
		if (!isDev()) return;

		// Première execution
		if (!window?.formik) {
			window.formik = formik;
		} else {
			let edited = reduce(
				formik,
				(result, value, key) => {
					return isEqual(value, window.formik[key]) ? result : result.concat({ [key]: value });
				},
				[]
			);

			edited.forEach((edit) => {
				const [key] = Object.keys(edit);
				if (typeof edit[key] !== "function") console.log(key, edit[key]);
			});

			window.formik = formik;
		}
	}, [formik]);

	return (
		isDev() &&
		visible && (
			<DebugScreen container>
				{/* Titre avec bouton pour fermer */}
				<Grid item xs={12}>
					<Card square>
						<Grid container item xs={12} justify="space-between">
							<Typography variant="subtitle2">{t("translation:formikDebugScreen")}</Typography>
							<IconButton size="small" onClick={() => setVisible(false)}>
								<Close />
							</IconButton>
						</Grid>
					</Card>
				</Grid>

				{/* Les différentes sections */}
				<Grid item xs={12}>
					<Collapse model="formik" title="GlobalState">
						<Code>{JSON.stringify(rest, null, 2)}</Code>
					</Collapse>

					<Collapse model="formik" title="InitialValues">
						<Code>{JSON.stringify(initialValues, null, 2)}</Code>
					</Collapse>

					<Collapse model="formik" title="Values">
						<Code>{JSON.stringify(values, null, 2)}</Code>
					</Collapse>
				</Grid>
			</DebugScreen>
		)
	);
}

Debug.propTypes = {
	formik: PropTypes.object.isRequired,
};

export default Debug;
