import { Chip, ListItem, ListItemText } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { lighten, rgba } from "polished";
import React, { createRef, useEffect } from "react";
import styled from "styled-components";

const Category = styled(ListItem)`
	/* padding-top: ${(props) => props.theme?.spacing(3)}px;
	padding-bottom: ${(props) => props.theme?.spacing(3)}px;
	padding-left: ${(props) => props.theme?.spacing(6)}px;
	padding-right: ${(props) => props.theme?.spacing(5)}px; */
	/* font-weight: ${(props) => props.theme.typography.fontWeightRegular}; */

	svg {
		color: ${(props) => props.theme.sidebar.color};
		/* font-size: 20px;
		width: 20px;
		height: 20px;
		opacity: 0.5; */
	}

	&:hover {
		background-color: ${(props) => lighten(0.1, props.theme.sidebar.background)};
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => lighten(0.2, props.theme.sidebar.background)};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const CategoryText = styled(ListItemText)`
	margin: 0;
	// white-space: normal;

	span {
		color: ${(props) => props.theme.sidebar.color};
		/* font-size: ${(props) => props.theme.typography.body1.fontSize}px; */
		/* font-weight: ${(props) => props.theme.sidebar.category.fontWeight}; */
		/* padding: 0 ${(props) => props.theme.spacing(2.5)}px; */
	}
`;

const CategoryIconLess = styled(ExpandLess)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
	color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 12px;
	top: 8px;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

const CategoryBadge = styled(LinkBadge)`
	top: 12px;
`;

export default function SidebarCategory({ name, icon, isOpen, isCollapsable, badge, sidebarOpen, ...rest }) {
	// #region Correction de sursaut du menu à cause du whitespace: normal pendant la transition
	// Ce code est nécessaire pour n'appliquer le whitespace qu'à la fin de la transition du menu
	// Je n'ai pas trouvé de meilleure solution pour empêcher les éléments du menu de "sursauter" quand on ouvre la sidebar
	const textRef = createRef(null);

	useEffect(() => {
		let uInt = setTimeout(() => {
			if (textRef.current) textRef.current.style.whiteSpace = sidebarOpen ? "normal" : "nowrap";
		}, 200);

		return () => clearTimeout(uInt);
	}, [sidebarOpen, textRef]);
	// #endregion Correction de sursaut du menu à cause du whitespace: normal pendant la transition

	return (
		<Category {...rest}>
			{icon}
			<CategoryText ref={textRef}>{name}</CategoryText>
			{isCollapsable ? isOpen ? <CategoryIconMore /> : <CategoryIconLess /> : null}
			{badge ? <CategoryBadge label={badge} /> : ""}
		</Category>
	);
}
