const currency_symbols = {
	USD: "$", // US Dollar
	EUR: "€", // Euro
	CRC: "₡", // Costa Rican Colón
	GBP: "£", // British Pound Sterling
	ILS: "₪", // Israeli New Sheqel
	INR: "₹", // Indian Rupee
	JPY: "¥", // Japanese Yen
	KRW: "₩", // South Korean Won
	NGN: "₦", // Nigerian Naira
	PHP: "₱", // Philippine Peso
	PLN: "zł", // Polish Zloty
	PYG: "₲", // Paraguayan Guarani
	THB: "฿", // Thai Baht
	UAH: "₴", // Ukrainian Hryvnia
	VND: "₫", // Vietnamese Dong
};

const defaultCurrency = "EUR";

export function currencyCodeToSymbol(code) {
	return currency_symbols[code] || currency_symbols[defaultCurrency];
}
