import { Input, InputLabel } from "@material-ui/core";
import { Search as MuiSearchIcon } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IconButton } from "./Buttons";

const SearchIcon = styled(MuiSearchIcon)`
	vertical-align: middle;
`;

function Search({ searchFilterChanging }) {
	const { t } = useTranslation();

	const [searchShowing, setSearchShowing] = useState(false); // Est-ce qu'on affiche la barre de recherche ?
	const [searchFilter, setSearchFilter] = useState(""); // Ce qui est affiché dans la barre de recherche

	const handleFilterChanging = (value) => {
		setSearchFilter(value);
		searchFilterChanging(value);
	};

	const onEscape = () => {
		setSearchShowing(false);
		setSearchFilter("");
		searchFilterChanging("");
	};

	return (
		<>
			<IconButton title={t("translation:search")} icon={<SearchIcon />} onClick={() => setSearchShowing(!searchShowing)} />
			<InputLabel style={{ display: "inline-block" }}>
				{searchShowing ? (
					<Input
						autoFocus
						onBlur={() => {
							if (searchFilter === "") setSearchShowing(false);
						}}
						placeholder={t("translation:search")}
						id="search"
						onKeyUp={(e) => e.key === "Escape" && onEscape()}
						value={searchFilter}
						onChange={(e) => handleFilterChanging(e.target.value)}
					/>
				) : null}
			</InputLabel>
		</>
	);
}

export default Search;
