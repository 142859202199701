import React from "react";
import { Tooltip as MuiTooltip } from "@material-ui/core";

const Tooltip = React.forwardRef((props, ref) => {
	return (
		<MuiTooltip ref={ref} {...props}>
			<span>{props.children}</span>
		</MuiTooltip>
	);
});

Tooltip.propTypes = {
	...MuiTooltip.propTypes,
};

export default Tooltip;
