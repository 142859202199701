import React, { createContext, useContext, useEffect, useState } from "react";

const PurchaseHeaderToSync = createContext();

const PURCH_HEADER = "purchaseHeaderToUpload";

export const usePurchaseHeaderToSync = () => useContext(PurchaseHeaderToSync);

export const PurchaseHeaderToSyncProvider = ({ children }) => {
	const [purchaseHeaders, setPurchaseHeaders] = useState(JSON.parse(localStorage.getItem("purchaseHeaderToUpload")) ?? []);

	function setPurchaseHeadersToSync(value) {
		setPurchaseHeaders(value);
		localStorage.setItem(PURCH_HEADER, JSON.stringify(value));
	}

	useEffect(() => {
		function checkUserData() {
			console.log('Event "storage" was fired, checking if the user has purchase headers to be uploaded..');

			const item = localStorage.getItem(PURCH_HEADER);

			if (item) {
				console.log(`The user has ${JSON.parse(item).length} purchase headers to be uploaded.`);
				setPurchaseHeaders(JSON.parse(item));
			}
		}

		window.addEventListener("storage", checkUserData);

		return () => {
			window.removeEventListener("storage", checkUserData);
		};
	}, []);

	return <PurchaseHeaderToSync.Provider value={{ purchaseHeaders, setPurchaseHeadersToSync }}>{children}</PurchaseHeaderToSync.Provider>;
};
