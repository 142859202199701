import React, { createContext, useContext, useEffect, useState } from "react";
import { isLoggedIn } from "../../../api";
import { fetcher } from "../../../api/fetcher";

const OnlineStatusContext = createContext();

export const useOnlineStatus = () => useContext(OnlineStatusContext);

export const OnlineStatusProvider = ({ children }) => {
	const [isOnline, setIsOnline] = useState(navigator.onLine);
	const CUSTOMERS = "customers";

	useEffect(() => {
		function handleOnline() {
			if (!isLoggedIn()) return;

			// #region Mise en cache de la liste des clients pour la recherche hors connexion
			const customers = JSON.parse(localStorage.getItem(CUSTOMERS));
			const now = new Date().getTime();

			if (customers === null || now - customers.lastchecked >= 24 * 60 * 60 * 1000) {
				fetcher(null, "get", "/customer")
					.then((customers) => {
						const storage = {
							lastchecked: now,
							...customers,
						};

						localStorage.setItem(CUSTOMERS, JSON.stringify(storage));
					})
					.catch((err) => console.error(err));
			}
			// #endregion Mise en cache de la liste des clients pour la recherche hors connexion

			return setIsOnline(true);
		}
		function handleOffline() {
			return setIsOnline(false);
		}

		window.addEventListener("online", handleOnline);
		window.addEventListener("offline", handleOffline);

		handleOnline();

		return () => {
			window.removeEventListener("online", handleOnline);
			window.removeEventListener("offline", handleOffline);
		};
	}, []);

	return <OnlineStatusContext.Provider value={isOnline}>{children}</OnlineStatusContext.Provider>;
};
