import {
	Button,
	ButtonGroup,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Paper,
	styled,
	Typography,
} from "@material-ui/core";
import { Cloud, CloudUpload, FilterList, Fingerprint, RemoveRedEye, SendRounded } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import { get } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { EnhancedTable, IconButton, LoadingButtonWithIcon as MyButton, LoadingIconButton, useCustomSnackbar } from ".";
import useFetch, { fetcher } from "../../api/fetcher";
import { _arrayBufferToBase64 } from "../../api/files";
import { getDevMode } from "../../api/localStorage";
import "../../css/app.css";
import variants from "../../theme/variants";

const LoadingButtonWithIcon = styled(MyButton)(spacing);

// ! EDIT (af1b9faba998c140d40e3479f9dd04dbace30bed): L'administrateur possède tout les droits sur les actions de GED, ce qui peut causer des bugs si nav n'est pas paramétré correctement

function CustomGEDRows({ row, setDocumentToUpload, handleSendByMail, documentSending, handleSignDoc }) {
	const { t } = useTranslation();

	const [signLoading, setSignLoading] = useState(false); // Chargement du bouton "signer"

	return (
		<ButtonGroup color="primary">
			{/* Ouvrir */}
			<IconButton
				icon={<RemoveRedEye />}
				title={t("translation:open")}
				disabled={get(row, "UrlPDF", null) == null} // L'administrateur devrait avoir le droit d'ouvrir tous les fichiers si l'url existe
				onClick={() => {
					// Double vérif, au cas où disabled ne serait pas bien configuré..
					if (get(row, "UrlPDF", null) !== null) {
						window.open(row?.UrlPDF, "_blank");
					}
				}}
			/>
			{/* Upload */}
			<IconButton
				icon={<CloudUpload />}
				title={t("translation:replaceDocument")}
				onClick={() => setDocumentToUpload(row)}
				disabled={!get(row, "SecurityUpload", null) ?? true}
			/>
			{/* Envoyer */}
			<IconButton
				icon={<SendRounded />}
				title={t("translation:send")}
				onClick={() => handleSendByMail(row)}
				loading={!get(row, "DocumentId", null) === documentSending}
				disabled={(!get(row, "SecuritySend", null) || !get(row, "UrlPDF", null)) ?? true}
			/>
			{/* Signer */}
			<LoadingIconButton
				icon={<Fingerprint />}
				title={t("translation:electronicSign")}
				onClick={() => handleSignDoc(row, setSignLoading)}
				disabled={(!get(row, "SecuritySigning", null) || !get(row, "UrlPDF", null)) ?? true}
				loading={signLoading}
			/>
		</ButtonGroup>
	);
}

/**
 * Module de GED (Gestion Electronique des Documents)
 * @param {contractorNo, commercialAgreement: undefined} props Props
 * !								/!\ Un porté doit être défini
 * TODO Si un contractorNo est undefined, utiliser le porté de Identity.getContractorFilter() dans le proxy
 */
export default function GED({ contractorNo, commercialAgreement, invoiceRequest, workContract, convention, chargeToValidate, chargeHistory }) {
	// #region Hooks
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();
	// #endregion Hooks

	// #region States
	const [open, setOpen] = useState(false);
	const [documents, setDocuments] = useState(undefined);
	const [documentSending, setDocumentSending] = useState(undefined);
	const [documentToUpload, setDocumentToUpload] = useState(undefined); // Données du document à remplacer ou fusioner
	const [headCells, setHeadCells] = useState([
		{ id: "RelationId", type: "int", primary: true, active: false },
		{ id: "DocType", type: "string", active: false },
		{ id: "DocName", type: "string" },
		{ id: "DocDescription", type: "string" },
		{
			id: "actions",
			type: "custom",
			content: ({ row }) =>
				React.cloneElement(<CustomGEDRows />, {
					row,
					setDocumentToUpload,
					handleSendByMail,
					documentSending,
					handleSignDoc,
				}),
		},
	]);
	// #endregion States

	// #region Récupération des données
	let params = "";
	params += commercialAgreement ? `&commercialAgreement=${commercialAgreement}` : "";
	params += invoiceRequest ? `&invoiceRequest=${invoiceRequest}` : "";
	params += workContract ? `&workContract=${workContract}` : "";
	params += convention ? `&convention=${convention}` : "";
	params += chargeToValidate ? `&chargeToValidate=${chargeToValidate}` : "";
	params += chargeHistory ? `&chargeHistory=${chargeHistory}` : "";

	// ContractorNo est un filtre obligatoire pour que la table temporaire puisse trouver les documents de la session.
	const { data, refetch, refetchLoading, setRefetchLoading } = useFetch("GET", `/documents?contractor=${contractorNo}${params}`);

	useEffect(() => {
		setDocuments(data?.Documents || []);
	}, [data, chargeToValidate]);
	// #endregion Récupération des données

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	const handleSignDoc = useCallback(
		(row, setSignLoading) => {
			if (!contractorNo) return console.error("Le numéro de porté est null");

			setSignLoading(true);
			fetcher(signal, "POST", "documents/send-for-signature", {
				DocumentID: row?.DocumentId,
				PageNo: getPageNo(),
				DocumentType: row?.DocType,
				contractorNo: contractorNo,
				DocumentName: row?.DocName,
			})
				.then((res) => {
					if (res?.Success === false) {
						return snackbar.showError(t("translation:errorDuringRequest"));
					}

					return snackbar.showSuccess(t("translation:documentSent"));
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("translation:errorDuringRequest"));
				})
				.finally(() => {
					setSignLoading(false);
				});
		}, // eslint-disable-next-line react-hooks/exhaustive-deps
		[contractorNo]
	);

	// #region Handlers
	const handleSendByMail = useCallback(
		(row) => {
			if (!contractorNo) {
				console.error("Le numéro de porté a été null.");
				return;
			}
			setDocumentSending(row?.DocumentId);
			fetcher(signal, "POST", "documents/send-by-mail", {
				DocumentID: row?.DocumentId,
				PageNo: getPageNo(),
				DocumentType: row?.DocType,
				contractorNo: contractorNo,
				DocumentName: row?.DocName,
			})
				.then((res) => {
					if (res?.Success) {
						snackbar.showSuccess(t("translation:documentSent"));
						return;
					}
					snackbar.showError(t("translation:errorDuringRequest"));
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("translation:errorDuringRequest"));
				})
				.finally(() => {
					setDocumentSending(undefined);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[contractorNo]
	);
	// #endregion Handlers

	// Permet de trouver le numéro de la page associé à un document
	const getPageNo = () => {
		return invoiceRequest || commercialAgreement || workContract || convention || chargeToValidate || chargeHistory || contractorNo;
	};

	return (
		<>
			<LoadingButtonWithIcon
				icon={<Cloud />}
				color="primary"
				variant="contained"
				ml={2}
				onClick={() => setOpen(true)}
				disabled={!contractorNo}
				loading={false}
			>
				<span className="text">{t("translation:openGed")}</span>
			</LoadingButtonWithIcon>

			<Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xl">
				<UploadReplaceMerge
					document={documentToUpload}
					pageNo={getPageNo()}
					onClose={() => setDocumentToUpload(false)}
					refetch={refetch}
					contractorNo={contractorNo}
				/>

				<DialogTitle
					style={{
						backgroundColor: variants[0].palette.primary.main,
						color: variants[0].palette.primary.contrastText,
					}}
				>
					<Typography variant="h3">{t("translation:documents")}</Typography>
				</DialogTitle>

				<DialogContent dividers>
					{/* Chip affichant les filtres utilisés pour la requête, visible en mode dev uniquement */}
					{getDevMode() && (
						<>
							<Chip icon={<FilterList />} label={`contractorNo: ${contractorNo}`} />
							{commercialAgreement && <Chip icon={<FilterList />} label={`commercialAgreement: ${commercialAgreement}`} />}
							{invoiceRequest && <Chip icon={<FilterList />} label={`invoiceRequest: ${invoiceRequest}`} />}
							{workContract && <Chip icon={<FilterList />} label={`workContract: ${workContract}`} />}
							{convention && <Chip icon={<FilterList />} label={`convention: ${convention}`} />}
							{chargeToValidate && <Chip icon={<FilterList />} label={`chargeToValidate: ${chargeToValidate}`} />}
							{chargeHistory && <Chip icon={<FilterList />} label={`chargeHistory: ${chargeHistory}`} />}
						</>
					)}
					<EnhancedTable
						id="documents"
						orderData="DocName"
						orderDir="asc"
						headCells={headCells}
						setHeadCells={setHeadCells}
						rows={documents}
						rowsColor={(current) => (current?.UrlPDF ? "green" : "red")}
						refetch={refetch}
						refetchLoading={refetchLoading}
						setRefetchLoading={setRefetchLoading}
						disablePagination
						fullWidth
					/>
				</DialogContent>

				<DialogActions>
					<Button variant="contained" onClick={() => setOpen(false)} color="primary">
						{t("translation:close")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

const UploadReplaceMerge = ({ document, pageNo, onClose, refetch, contractorNo }) => {
	// #region States
	const [loading, setLoading] = useState(false); // State bool: chargement du document ?
	const [fileToReplace, setFileToReplace] = useState(undefined);
	// #endregion States

	// #region Hooks
	const snackbar = useCustomSnackbar();
	const { t } = useTranslation();
	const onDrop = useCallback((acceptedFiles) => {
		if (acceptedFiles[0] !== null) {
			// Un document a été uploadé, le traiter puis l'uploader.
			setLoading(true);
			setFileToReplace(acceptedFiles[0]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		// console.log({ document });
		// console.log(fileToReplace);
		if (!fileToReplace) return;

		const fileReader = new FileReader();
		fileReader.readAsArrayBuffer(fileToReplace);
		fileReader.onload = () => {
			fetcher(signal, "POST", "documents/replace", {
				NavName: document?.DocName,
				NavType: document?.DocType,
				Type: document?.DocType,
				Name: fileToReplace.name,
				PageNo: pageNo,
				Bytes: _arrayBufferToBase64(fileReader.result),
				ContractorNo: contractorNo,
			})
				.then((res) => {
					if (res?.Success === false) snackbar.showError(t("translation:errorDuringRequest"));
					if (res?.Success === true) {
						// Court delais avant de fermer afin d'empecher l'affichage du msg d'erreur malgres le succes lors de l'upload
						setTimeout(() => {
							onClose();
						}, "1");
						snackbar.showSuccess(t("translation:success"));
						refetch();
					}
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("translation:errorDuringRequest"));
				})
				.finally(() => {
					setFileToReplace(null);
					setLoading(false);
				});
		};
		fileReader.onerror = () => {
			console.error("Erreur file reader");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [document, fileToReplace, pageNo]);

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Hooks

	return (
		<>
			{/** Boîtes de dialogues et avertissements */}

			{/** Dialogue de remplacement de fichier */}
			<Dialog open={!!document} onClose={() => onClose()} fullWidth maxWidth="md">
				<DialogTitle
					style={{
						backgroundColor: variants[0].palette.primary.main,
						color: variants[0].palette.primary.contrastText,
					}}
				>
					<Typography variant="h3">{t("translation:replaceDocument")}</Typography>
					<Typography variant="h5">{document?.DocName}</Typography>
				</DialogTitle>
				<DialogContent>
					<Paper variant="outlined" style={{ padding: 20 }} {...getRootProps({ className: "dropzone" })}>
						{loading ? (
							<CircularProgress />
						) : (
							<>
								<input id="fileToUpload" name="fileToUpload" {...getInputProps()} />

								{!isDragActive ? (
									<p style={{ textAlign: "center" }}>{t("translation:dragAndDropFileToAdd")}</p>
								) : (
									<p style={{ textAlign: "center" }}>{t("translation:youCanRelease")}</p>
								)}
							</>
						)}
					</Paper>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} onClick={() => onClose()}>
						{t("translation:cancel")}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
