const props = {
	MuiButtonBase: {
		disableRipple: true,
	},
	MuiCardHeader: {
		titleTypographyProps: { variant: "h6" },
	},
	MuiButton: {
		variant: "contained",
		color: "primary",
	},
};

export default props;
