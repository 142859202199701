import { useCustomSnackbar } from "../pages/components/Snackbar";
import { useTranslation } from "react-i18next";

export default function ErrorsApi() {
	const snackbar = useCustomSnackbar();
	const { t } = useTranslation();

	document.addEventListener("api_error", (errorApi) => {
		if (errorApi) {
			// console.error(errorApi);
			switch (errorApi.detail.status) {
				case 500:
				default:
					snackbar.showError(t("translation:errorInternal"));
					break;

				case 200:
					snackbar.showInfo(errorApi.detail.message);
					break;
			}
		}
	});

	return null;
}
