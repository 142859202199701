import { Box, Collapse, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore, Sync } from "@material-ui/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { fetcher } from "../../api/fetcher";
import { LoadingButtonWithIcon } from "../../pages/components";
import { useOnlineStatus } from "../../pages/pages/offline/useOfflineStatusProvider";
import { usePurchaseHeaderToSync } from "../../pages/pages/offline/usePurchaseHeadersToSync";
import variants from "../../theme/variants";
import "./OfflineSync.css";

export default function OfflineSync() {
	const { t } = useTranslation();
	const { purchaseHeaders, setPurchaseHeadersToSync } = usePurchaseHeaderToSync();
	const isOnline = useOnlineStatus();

	const [dialogOpen, setDialogOpen] = useState(false);
	const [openIndex, setOpenIndex] = useState([]);

	const [isSyncing, setIsSyncing] = useState(false);
	const [completionStatus, setCompletionStatus] = useState(purchaseHeaders.map(() => null));
	function handleSync() {
		setIsSyncing(true);
		setCompletionStatus(purchaseHeaders.map(() => null)); // Juste au cas où c'est pas la première fois qu'on lance l'opération

		// On créé un tableau de promesses correspondant à chaque purchase header à synchro
		const requests = purchaseHeaders.map((purchase, i) =>
			fetcher(null, "POST", "/purchase-header/simplified", purchase)
				.then((resp) => {
					completionStatus[i] = resp?.Success ?? false;
					setCompletionStatus([...completionStatus]);

					if (resp.Success === false) return Promise.reject(resp.ErrorMsg);
					else return resp;
				})
				.catch((err) => {
					completionStatus[i] = false;
					setCompletionStatus([...completionStatus]);
					return Promise.reject(err);
				})
		);

		// On attends qu'elles ait toutes fini, puis on supprime celles qui ont bien abouti, ne laissant que celles qui n'ont pas réussi, de sorte que le client puisse relancer une opération s'il le souhaite
		Promise.allSettled(requests).then((result) => {
			setIsSyncing(false);

			// Le setTimeout vu qu'il y a une animation CSS de 1,5s
			setTimeout(() => {
				let rejected = purchaseHeaders.filter((_, i) => {
					return result[i].status === "rejected";
				});
				setPurchaseHeadersToSync(rejected);
				setCompletionStatus(rejected.map(() => false));
			}, 1500);
		});
	}

	return (
		<>
			<Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth maxWidth="sm">
				<DialogTitle
					style={{
						backgroundColor: variants[0].palette.primary.main,
						color: variants[0].palette.primary.contrastText,
					}}
				>
					{t("Synchroniser les prestations")}
				</DialogTitle>

				<DialogContent>
					<List>
						{Array.isArray(purchaseHeaders) &&
							purchaseHeaders.map((purchase, i) => (
								<React.Fragment key={i}>
									<ListItem
										className={completionStatus[i] === true ? "success" : completionStatus[i] === false ? "error" : ""}
										button
										item
										xs={12}
										onClick={() => {
											if (openIndex.indexOf(i) === -1) {
												setOpenIndex([...openIndex, i]);
											} else {
												setOpenIndex(openIndex.filter((index) => index !== i));
											}
										}}
									>
										<ListItemText
											primary={`Adhérent : ${purchase?.CustomerName ?? purchase?.CustomerNo}`}
											secondary={`Date : ${new Date(purchase.DocumentDate).toLocaleDateString()}`}
										/>

										{Array.isArray(purchase.Lines) && purchase.Lines.length !== 0 ? (
											openIndex.indexOf(i) !== -1 ? (
												<ExpandLess />
											) : (
												<ExpandMore />
											)
										) : null}
									</ListItem>

									<Collapse in={openIndex.indexOf(i) !== -1} timeout="auto" unmountOnExit>
										<List disablePadding dense>
											{purchase.Lines.map((line, i) => {
												return (
													<ListItem key={i}>
														<ListItemText
															primary={line.Description}
															secondary={`Quantité : ${line.Quantity}, prix unitaire : ${line.DirectUnitCost}`}
														/>
													</ListItem>
												);
											})}
										</List>
									</Collapse>
								</React.Fragment>
							))}
					</List>

					{!purchaseHeaders || purchaseHeaders.length === 0 ? (
						<Typography>{"Aucune prestation à synchroniser."}</Typography>
					) : (
						<Box display="flex" justifyContent="flex-end">
							<LoadingButtonWithIcon loading={isSyncing} icon={<Sync />} onClick={handleSync} disabled={!isOnline}>
								{"Tout synchroniser"}
							</LoadingButtonWithIcon>
						</Box>
					)}
				</DialogContent>
			</Dialog>

			<Typography onClick={() => setDialogOpen(true)}>{`Vous avez ${purchaseHeaders?.length ?? 0} prestations à synchroniser`}</Typography>
		</>
	);
}
