import { Chip, ListItem, ListItemText } from "@material-ui/core";
import { lighten, rgba } from "polished";
import React from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import styled from "styled-components";
import { Tooltip } from "../../../pages/components";

const NavLink = React.forwardRef((props, ref) => <RouterNavLink innerRef={ref} {...props} />);

const Link = styled(ListItem)`
	padding-left: ${(props) => props.theme.spacing(props.open ? 10 : 4)}px;
	padding-top: ${(props) => props.theme.spacing(1)}px;
	padding-bottom: ${(props) => props.theme.spacing(1)}px;

	&:hover {
		background-color: ${(props) => lighten(0.1, props.theme.sidebar.background)};
	}

	span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
	}

	&:hover span {
		color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
	}

	&.${(props) => props.activeClassName} {
		background-color: ${(props) => lighten(0.2, props.theme.sidebar.background)};

		span {
			color: ${(props) => props.theme.sidebar.color};
		}
	}
`;

const LinkText = styled(ListItemText)`
	color: ${(props) => props.theme.sidebar.color};
	span {
		font-size: ${(props) => props.theme.typography.body1.fontSize}px;
	}
	margin-top: 0;
	margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
	font-size: 11px;
	font-weight: ${(props) => props.theme.typography.fontWeightBold};
	height: 20px;
	position: absolute;
	right: 12px;
	top: 8px;
	background: ${(props) => props.theme.sidebar.badge.background};

	span.MuiChip-label,
	span.MuiChip-label:hover {
		cursor: pointer;
		color: ${(props) => props.theme.sidebar.badge.color};
		padding-left: ${(props) => props.theme.spacing(2)}px;
		padding-right: ${(props) => props.theme.spacing(2)}px;
	}
`;

export default function SidebarLink({ name, to, badge, open }) {
	return (
		<Tooltip title={(!open && name) || ""} placement="right">
			<Link button dense component={NavLink} exact to={to} activeClassName="active" open={open}>
				<LinkText>{(open && "- " + name) || "—"}</LinkText>
				{badge ? <LinkBadge label={badge} /> : ""}
			</Link>
		</Tooltip>
	);
}
