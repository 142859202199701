import { Button, CircularProgress, IconButton as MuiIconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Tooltip } from ".";

const ButtonNoText = styled(Button)`
	& .MuiButton-startIcon,
	& .MuiButton-endIcon {
		margin: 0 auto;
	}
`;

export const LoadingButton = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { loading, ...rest } = props;
	let { iconPosition, ...buttonProps } = rest;
	let endIcon, startIcon;

	if (iconPosition === "end") {
		endIcon = loading ? <CircularProgress color="inherit" size={20} /> : null;
	} else {
		startIcon = loading ? <CircularProgress color="inherit" size={20} /> : null;
	}

	return (
		<Button ref={ref} startIcon={startIcon} endIcon={endIcon} disabled={loading ? true : false} {...buttonProps}>
			{props.children}
		</Button>
	);
});

export const LoadingButtonWithIcon = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { loading, icon, ...rest } = props;
	let { iconPosition, startIcon, endIcon, ...buttonProps } = rest;

	if (iconPosition === "end") {
		endIcon = loading ? <CircularProgress color="inherit" size={20} /> : icon;
	} else {
		startIcon = loading ? <CircularProgress color="inherit" size={20} /> : icon;
	}

	return (
		<Button ref={ref} startIcon={startIcon} endIcon={endIcon} disabled={loading ? true : false} {...buttonProps}>
			{props.children}
		</Button>
	);
});

export const LoadingIconButton = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	// Si l'IconButton fait parti d'un ButtonGroup, la prop fullWidth est automatiquement assigné à faux,
	// ce qui génère une erreur dans la console. Pour la supprimer, il sufft de ne pas tenir compte de cette prop
	const { loading, icon, title, fullWidth, ...rest } = props; // eslint-disable-line no-unused-vars

	let loadingIcon = loading ? <CircularProgress color="inherit" size={24} /> : icon;

	return (
		<Tooltip title={title}>
			<MuiIconButton ref={ref} disabled={loading ? true : false} {...rest}>
				{loadingIcon}
			</MuiIconButton>
		</Tooltip>
	);
});

export const IconButton = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	// Si l'IconButton fait parti d'un ButtonGroup, la prop fullWidth est automatiquement assigné à faux,
	// ce qui génère une erreur dans la console. Pour la supprimer, il sufft de ne pas tenir compte de cette prop
	const { icon, title, fullWidth, ...rest } = props; // eslint-disable-line no-unused-vars

	return (
		<Tooltip title={title}>
			<MuiIconButton {...rest} ref={ref}>
				{icon}
			</MuiIconButton>
		</Tooltip>
	);
});

export const ButtonWithIcon = React.forwardRef((props, ref) => {
	// On ne récupère que ce dont on à besoins
	const { icon, ...rest } = props;
	let { iconPosition, startIcon, endIcon, ...buttonProps } = rest;

	if (iconPosition === "end") {
		endIcon = icon;
	} else {
		startIcon = icon;
	}

	return <ButtonNoText ref={ref} {...buttonProps} startIcon={startIcon} endIcon={endIcon} />;
});

LoadingButton.propTypes = {
	loading: PropTypes.bool.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
LoadingButton.defaultProps = {
	iconPosition: "start",
};

LoadingButtonWithIcon.propTypes = {
	loading: PropTypes.bool.isRequired,
	icon: PropTypes.node.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
LoadingButtonWithIcon.defaultProps = {
	iconPosition: "start",
};

ButtonWithIcon.propTypes = {
	icon: PropTypes.node.isRequired,
	iconPosition: PropTypes.oneOf(["start", "end"]),
	...Button.propTypes,
};
ButtonWithIcon.defaultProps = {
	iconPosition: "start",
};

LoadingIconButton.propTypes = {
	loading: PropTypes.bool.isRequired,
	icon: PropTypes.node.isRequired,
	title: PropTypes.node.isRequired,
	...IconButton.propTypes,
};
IconButton.propTypes = {
	icon: PropTypes.node.isRequired,
	title: PropTypes.node.isRequired,
	...IconButton.propTypes,
};
