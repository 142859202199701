import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { i18nextPlugin } from "translation-check";
import { getDevMode } from "../api/localStorage";
import imports from "./imports";

// #region Constantes de debug
const allTranslationKeys = [];
const allTranslationValues = {};
const debugSpecificString = null; // mettre ici une clé de traduction à débugger, tous les charactères non alpha-numériques sont supprimés de la recherhe
const debugPossibleDuplicate = false;
const disableAllLogs = true;
// #endregion Constantes de debug

// Agglomération de tous les fichiers de traductions en un objet utilisable par i18next
// !! Si des clés de traduction sont en double dans les imports, seule la dernière rencontré est conservée
const compileImports = (imports) => {
	const langs = imports.langs;
	let res = {};

	// Initialisation dynamique de toutes les langues
	for (const langKey in langs) {
		// res.fr = ...
		res[imports.langs[langKey]] = { translation: {} };
	}

	// Pour tous les namespaces
	for (const namespace in imports.namespaces) {
		const dict = imports.namespaces[namespace];

		// Pour toutes les traductions
		for (const translationKey in dict) {
			const elem = dict[translationKey];

			// #region Debug de traductions
			let basicTranslationKey = translationKey.toLowerCase().replace(/[^a-z0-9]/, "");

			if (debugPossibleDuplicate && allTranslationKeys.indexOf(basicTranslationKey) !== -1) {
				let key = `${namespace}:${allTranslationValues[basicTranslationKey].translationKey}`,
					value = allTranslationValues[basicTranslationKey].elem;

				!disableAllLogs &&
					console.warn(
						`[i18next] [POSSIBLE DUPLICATE] key "${translationKey}""\n`,
						JSON.stringify(
							{
								// La traduction en double depuis la liste
								[key]: value,
								// Celle qu'on viens de trouver en double
								[`${namespace}:${translationKey}`]: elem,
							},
							null,
							2
						)
					);
			} else if (res.fr.translation[translationKey] !== undefined) {
				!disableAllLogs &&
					console.error(
						`[i18next] [EXACT MATCH] Duplicate key "${translationKey}" in namespace "${namespace}"\n`,
						JSON.stringify(
							{
								before: {
									ENU: res.en.translation[translationKey],
									FRA: res.fr.translation[translationKey],
								},
								after: elem,
							},
							null,
							2
						)
					);
			} else if (debugSpecificString !== null && basicTranslationKey.includes(debugSpecificString.toLowerCase().replace(/[^a-z0-9]/, ""))) {
				!disableAllLogs && console.info(`[i18next] [FOUND MATCH] "${translationKey}" in namespace "${namespace}"\n`, JSON.stringify(elem, null, 2));
			}

			allTranslationKeys.push(basicTranslationKey);
			allTranslationValues[basicTranslationKey] = {
				...allTranslationValues[basicTranslationKey],
				translationKey,
				namespace,
				elem,
			};
			// #endregion Debug de traductions

			// Pour toutes les langues
			for (const langKey in langs) {
				const translation = elem[langKey];

				// Création du namespace dans res s'il n'existe pas déjà
				if (res[langs[langKey]][namespace] === undefined) res[langs[langKey]][namespace] = {};

				// Ajout de la traduction dans le namespace par défaut
				res[langs[langKey]].translation[translationKey] = translation;
				// Ajout de la traduction dans le namespace dédié
				res[langs[langKey]][namespace][`${namespace}:${translationKey}`] = translation;
			}
		}
	}

	return res;
};

i18n
	.use(Backend)
	.use(LanguageDetector)
	.use(initReactI18next)
	.use(i18nextPlugin)
	.init({
		fallbackLng: "fr",
		ns: Object.keys(imports.namespaces),
		defaultNS: "translation",
		resources: compileImports(imports),

		debug: getDevMode(),

		// saveMissing: true,
		// Attention : cette fonction désactive la recherche de clés supplémentaire quand plusieurs arguments sont fournis
		// parseMissingKeyHandler: (key) => {
		// 	!disableAllLogs && console.error(`[i18next] [MISSING KEY] "${key}"`);
		// 	// return "";
		// 	return key;
		// },

		interpolation: {
			format: function (value, format) {
				if (format === "lowercase") return value.toLowerCase();
				return value;
			},
		},
	});

// i18next >= 21.3.0
// i18n.services.formatter.add("lowercase", (value) => {
// 	return value.toLowerCase();
// });

export default i18n;
