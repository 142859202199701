import React from "react";
import { connect } from "react-redux";

import Helmet from "react-helmet";

import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { StylesProvider } from "@material-ui/styles";
import { ThemeProvider } from "styled-components";

import { useTranslation } from "react-i18next";

import { getDevMode } from "./api/localStorage";
import { OnlineStatusProvider } from "./pages/pages/offline/useOfflineStatusProvider";
import { PurchaseHeaderToSyncProvider } from "./pages/pages/offline/usePurchaseHeadersToSync";
import Routes from "./routes/Routes";
import maTheme from "./theme";

function App({ theme }) {
	const { i18n, t } = useTranslation();

	if (getDevMode()) {
		window.i18n = i18n;
		window.t = t;
	}

	const selectedTheme = maTheme[theme.currentTheme](i18n.language);

	return (
		<React.Fragment>
			<Helmet titleTemplate="%s | soluGMR" defaultTitle="soluGMR" />
			<StylesProvider injectFirst>
				<MuiPickersUtilsProvider utils={DateFnsUtils}>
					<MuiThemeProvider theme={selectedTheme}>
						<ThemeProvider theme={selectedTheme}>
							<OnlineStatusProvider>
								<PurchaseHeaderToSyncProvider>
									<Routes />
								</PurchaseHeaderToSyncProvider>
							</OnlineStatusProvider>
						</ThemeProvider>
					</MuiThemeProvider>
				</MuiPickersUtilsProvider>
			</StylesProvider>
		</React.Fragment>
	);
}

export default connect((store) => ({ theme: store.themeReducer }))(App);
