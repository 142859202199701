// React & Material-ui
import {
	FormControl,
	FormControlLabel as MuiFormControlLabel,
	FormHelperText,
	IconButton,
	InputAdornment,
	InputLabel,
	ListItem,
	MenuItem,
	Select as MuiSelect,
	Switch,
	TextField as MuiTextField,
} from "@material-ui/core";
import { Autocomplete, Skeleton } from "@material-ui/lab";
import PropTypes from "prop-types";
import React, { useState } from "react";

// Traductions
import { camelCase, get, isEqual } from "lodash";
import { useTranslation } from "react-i18next";
// Customisation des éléments de material-ui
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { spacing } from "@material-ui/system";
import styled from "styled-components";
import { currencyCodeToSymbol } from "../../api/currency";
import { isValidDate } from "../../api/date";
import { pascalCase } from "../../api/strings";

const TextField = styled(MuiTextField)(spacing);
const Select = styled(MuiSelect)(spacing);
const FormControlLabel = styled(MuiFormControlLabel)(spacing);

function Prop({
	formik,
	prop,
	type = "text",
	value,
	mapOptions,
	currencyCode,
	toggleVisibility,
	variant = "outlined",
	disabled,
	valueHelper,
	emptyOptionsPlaceholder,
	...rest
}) {
	const { t } = useTranslation();
	const { model, options } = rest;

	const [isVisible, setIsVisible] = useState(toggleVisibility === undefined ? true : false);

	function getValue() {
		if (!formik?.values) return;
		let value = get(formik.values, prop, "");

		if (type === "date") {
			if (value === "01-01-0001" || value === "0001-01-01") return null;

			let creationDate = new Date(value);
			return isValidDate(creationDate) && creationDate.getTime() >= 0 ? creationDate.toISOString().split("T")[0] : value;
		} else if (type === "int" || type === "float") {
			let currValue;

			if (!value) {
				currValue = null;
			} else if (isNaN(value)) {
				currValue = value.replace(",", ".");
			} else {
				currValue = value;
			}

			// if (type === "float") {
			// 	currValue = currValue?.toPrecision(precision);
			// }

			return currValue;
		}
		// Si on a la valeur d'un enum, mais pas encore la liste d'options valides
		else if ((type === "enum" || type === "select" || type === "autocomplete") && (!Array.isArray(rest?.options) || rest.options?.length === 0)) {
			// console.log({ prop: prop, options: rest.options, value: formik.values?.[prop] });
			return "";
		} else {
			return value;
		}
	}

	function isFieldError(prop) {
		// Si ça retourne vrai, le champ est affiché en rouge pour indiquer une erreur
		// On essaye de ne pas afficher trop de champs en erreur à la fois, donc on ne retourne vrai que si le champ à été "touché"
		// ou si l'utilisateur à essayer de soumettre le formulaire

		return Boolean(
			// Si le formulaire à été soumis au moins une fois, ou que le champ à été touché...
			(formik.submitCount > 0 || formik.touched?.[prop]) &&
				// ...et que le champ est en erreur
				formik.errors?.[prop]
		);
	}

	function getHelperText(prop) {
		if (Array.isArray(valueHelper)) {
			// Si on trouve un texte d'aide correspondant à la valeure actuelle de formik.values[prop]
			// eslint-disable-next-line eqeqeq
			let helperText = valueHelper.find((el) => el.ifValueIs == get(formik.values, prop, ""))?.showText;
			return helperText ? t(`translation:${camelCase(helperText)}`) : null;
		}
		if (typeof rest?.helperText === "string") return rest.helperText;
		return isFieldError(prop) ? formik.errors?.[prop] : null;
	}

	function handleClickShowPassword() {
		setIsVisible((visible) => !visible);
	}

	function getEndAdornement() {
		if (typeof toggleVisibility === "boolean") {
			return (
				<InputAdornment position="end">
					<IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
						{isVisible ? <Visibility /> : <VisibilityOff />}
					</IconButton>
				</InputAdornment>
			);
		} else if (type === "currency") {
			return currencyCodeToSymbol(currencyCode);
		}

		// Dans tout les autres cas
		return null;
	}

	if (!formik || !prop) {
		return <Skeleton variant="text" height={40} width={150} animation="wave" />;
	} else if (type === "bool") {
		return (
			<>
				<FormControlLabel
					label={rest?.label ?? t(camelCase(prop))}
					control={
						<Switch
							id={prop}
							name={prop}
							checked={Boolean(getValue() ?? false)}
							onChange={formik.handleChange}
							onBlur={(event) => {
								formik.handleBlur(event);

								// Auto-submit du formulaire si la prop submitOnBlur est true
								if (formik?.submitOnBlur === true) {
									formik.handleSubmit();
								}
							}}
							disabled={formik.isSubmitting || disabled}
							{...rest}
						/>
					}
				/>
				<FormHelperText error={isFieldError(prop)} variant="outlined">
					{getHelperText(prop)}
				</FormHelperText>
			</>
		);
	} else if (type === "enum") {
		return (
			<FormControl variant="outlined" fullWidth>
				<InputLabel id={`select-${prop}`} error={isFieldError(prop)} shrink={options?.length === 0 ? true : undefined}>
					{rest?.label ?? t(camelCase(prop))}
				</InputLabel>
				<Select
					labelId={rest?.label !== "" && `select-${prop}`}
					id={prop}
					name={prop}
					variant={variant}
					value={getValue() ?? ""}
					displayEmpty={options?.length === 0}
					onChange={(event) => {
						formik.handleChange(event);
						// On appelle aussi le onBlur quand la donnée change sur les selects
						formik.handleBlur(event);
					}}
					onBlur={(event) => {
						formik.handleBlur(event);

						// Auto-submit du formulaire si la prop submitOnBlur est true
						if (formik?.submitOnBlur === true) {
							formik.handleSubmit();
						}
					}}
					error={isFieldError(prop)}
					disabled={formik.isSubmitting || options?.length === 0 || disabled}
					fullWidth
					my={2}
					{...rest}
				>
					{options?.length > 0 ? (
						options?.map((option) => {
							if (typeof mapOptions === "function") {
								let { key, value, label, disabled } = mapOptions(option);
								return (
									<MenuItem key={key} value={value} disabled={disabled}>
										{label}
									</MenuItem>
								);
							}

							return (
								<MenuItem key={option} value={option}>
									{t(`translation:${model}${pascalCase(prop.match(/^(.*\.)?([^.]*)$/)[2])}${pascalCase(option)}`)}
								</MenuItem>
							);
						})
					) : options?.length === 0 ? (
						<MenuItem key="" value="" disabled>
							{emptyOptionsPlaceholder ?? t("translation:selectEmptyOptions")}
						</MenuItem>
					) : (
						<Skeleton variant="text" />
					)}
				</Select>
				<FormHelperText error={isFieldError(prop)} variant="outlined">
					{getHelperText(prop)}
				</FormHelperText>
			</FormControl>
		);
	} else if (type === "autocomplete") {
		return (
			<FormControl variant="outlined" fullWidth>
				<Autocomplete
					label={rest?.label ?? t(camelCase(prop))}
					id={prop}
					name={prop}
					value={(value || getValue()) ?? ""}
					renderInput={(params) => <TextField {...params} label={rest?.label ?? t(camelCase(prop))} variant="outlined" />}
					onChange={(event, newValue) => {
						// OnChange pour les select ne fonctionne pas, il faut changer la valeure à la main
						formik.setFieldValue(prop, newValue);
						// On appelle aussi le onBlur quand la donnée change sur les selects
						formik.handleBlur(event);
					}}
					onBlur={(event) => {
						formik.handleBlur(event);

						// Auto-submit du formulaire si la prop submitOnBlur est true
						if (formik?.submitOnBlur === true) {
							formik.handleSubmit();
						}
					}}
					disabled={formik.isSubmitting || disabled}
					my={2}
					{...rest}
				>
					{options?.length > 0 ? (
						options?.map((option) => {
							if (typeof mapOptions === "function") {
								let { key, value, label } = mapOptions(option);
								return (
									<MenuItem key={key} value={value}>
										{label}
									</MenuItem>
								);
							}
							return (
								<MenuItem key={option} value={option}>
									{t(`translation:${model}${pascalCase(prop.match(/^(.*\.)?([^.]*)$/)[2])}${pascalCase(option)}`)}
								</MenuItem>
							);
						})
					) : (
						<Skeleton variant="text" />
					)}
				</Autocomplete>
				<FormHelperText error={isFieldError(prop)} variant="outlined">
					{getHelperText(prop)}
				</FormHelperText>
			</FormControl>
		);
	} else if (type === "select") {
		return (
			<TextField
				// Config de base pour tous les champs
				id={prop}
				name={prop}
				label={rest?.label ?? t(camelCase(prop))}
				select
				InputLabelProps={{
					shrink: type === "date" || getValue() !== null,
				}}
				value={(value || getValue()) ?? ""}
				inputProps={{
					step: type === "float" ? 0.1 : null,
				}}
				// Gestion de la modification
				onChange={(event) => {
					formik.handleChange(event);
					// On appelle aussi le onBlur quand la donnée change sur les selects
					formik.handleBlur(event);
				}}
				onBlur={(event) => {
					formik.handleBlur(event);
				}}
				// Validation
				error={isFieldError(prop)}
				helperText={getHelperText(prop)}
				disabled={formik.isSubmitting || disabled}
				// Style
				variant={variant}
				fullWidth
				my={2}
				{...rest}
			>
				{options?.length > 0 ? (
					options?.map((option) => {
						if (typeof mapOptions === "function") {
							let { key, value, label, disabled } = mapOptions(option);
							return (
								<ListItem key={key} value={value} disabled={disabled}>
									{label}
								</ListItem>
							);
						}

						return (
							<ListItem key={option} value={option}>
								{t(`translation:${model}${pascalCase(prop.match(/^(.*\.)?([^.]*)$/)[2])}${pascalCase(option)}`)}
							</ListItem>
						);
					})
				) : (
					<Skeleton variant="text" />
				)}
			</TextField>
		);
	} else {
		// #region Redéfinition du type pour le standard HTML
		let inputType,
			step = null;
		switch (type) {
			case "int":
				inputType = "number";
				step = 1;
				break;
			case "float":
			case "currency":
				inputType = "number";
				step = 0.1;
				break;

			default:
				inputType = !isVisible ? "password" : type;
		}
		// #endregion Redéfinition du type pour le standard HTML

		return (
			<TextField
				// Config de base pour tous les champs
				id={prop}
				name={prop}
				label={rest?.label ?? t(camelCase(prop))}
				type={inputType}
				InputLabelProps={{
					shrink: type === "date" || getValue() !== null,
				}}
				value={inputType === "date" && !getValue() ? "" : (value || getValue()) ?? ""} // Si c'est un type date, on s'assure de ne rien afficher dans le cas d'une date non valide
				InputProps={{
					step: step,
					endAdornment: getEndAdornement(),
				}}
				// Gestion de la modification
				onBlur={(event) => {
					formik.handleBlur(event);

					// Auto-submit du formulaire si la prop submitOnBlur est true
					if (formik?.submitOnBlur === true) {
						formik.handleSubmit();
					}
				}}
				onChange={(e) => {
					formik.handleChange(e);
				}}
				// Validation
				error={isFieldError(prop)}
				helperText={getHelperText(prop)}
				disabled={formik.isSubmitting || disabled}
				// Style
				variant={variant}
				fullWidth
				my={2}
				{...rest}
			/>
		);
	}
}

// #region Propriétés du composant
Prop.propTypes = {
	formik: PropTypes.object.isRequired,
	prop: PropTypes.string.isRequired,
	type: PropTypes.oneOf(["text", "bool", "date", "int", "float", "currency", "enum", "select", "autocomplete"]),
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),

	// Spé enum / select
	model: PropTypes.string, // Permet de générer des traductions pour chaque option
	options: PropTypes.array,
	mapOptions: PropTypes.func, // Fonction dont la valeure de retour doit toujours être un objet contenant key, value et label
	emptyOptionsPlaceholder: PropTypes.string, // Si le select est vide, permet d'afficher un texte d'aide à l'utilisateur

	// Spé currency
	currencyCode: PropTypes.string,
	toggleVisibility: PropTypes.bool,
};

Prop.defaultProps = {
	type: "text",
};

function PropsAreEqual(
	{
		formik: p_formik,
		prop: p_prop,
		type: p_type,
		value: p_value,
		model: p_model,
		options: p_options,
		mapOptions: p_mapOptions,
		currencyCode: p_currencyCode,
		helperText: p_helperText,
		disabled: p_disabled,
		...p_rest
	},
	{
		formik: n_formik,
		prop: n_prop,
		type: n_type,
		value: n_value,
		model: n_model,
		options: n_options,
		mapOptions: n_mapOptions,
		currencyCode: n_currencyCode,
		helperText: n_helperText,
		disabled: n_disabled,
		...n_rest
	}
) {
	if (!isEqual(p_rest.toString(), n_rest.toString())) {
		console.log({ p_rest, n_rest });
	}

	return (
		get(p_formik.values, p_prop) === get(n_formik.values, n_prop) &&
		get(p_formik.touched, p_prop) === get(n_formik.touched, n_prop) &&
		get(p_formik.errors, p_prop) === get(n_formik.errors, n_prop) &&
		p_formik?.isSubmitting === n_formik?.isSubmitting &&
		p_type === n_type &&
		p_value === n_value &&
		p_helperText === n_helperText &&
		p_disabled === n_disabled &&
		// Spé enum / select
		p_model === n_model &&
		isEqual(p_options, n_options) &&
		p_mapOptions?.toString() === n_mapOptions?.toString() &&
		// Spé currency
		p_currencyCode === n_currencyCode

		// Tout le reste
		// && isEqual(p_rest, n_rest)
	);
}
// #endregion Propriétés du composant

export default React.memo(Prop, PropsAreEqual);
