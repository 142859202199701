import { Grid, Table, TableBody, TableCell as MuiTableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import { Add as AddIcon, Delete as DeleteIcon } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import { FieldArray, FormikProvider } from "formik";
import { camelCase, get, isEqual } from "lodash";
import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { LoadingButtonWithIcon, LoadingIconButton } from ".";
import { useCustomSnackbar } from "./Snackbar";

const TableCell = styled(MuiTableCell)`
	padding: 0px 6px 4px 6px;
`;

function SubList({ formik, propertyName, headCells, addRowCallback, deleteRowCallback }) {
	// Variables globales nécessaire de base
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();

	// Variables d'état du composant
	const [lineNoToDelete, setLineNoToDelete] = useState(-1);
	const [addLineLoading, setAddLineLoading] = useState(false);

	return (
		<>
			{/* Bouton "Ajouter une ligne", présent que si addRowCallback est spécifié */}
			{typeof addRowCallback === "function" && (
				<Grid container justify="flex-end">
					<Grid item>
						<LoadingButtonWithIcon
							icon={<AddIcon />}
							variant="contained"
							color="secondary"
							loading={addLineLoading}
							disabled={addLineLoading}
							onClick={() => {
								setAddLineLoading(true);
								addRowCallback()
									.then((response) => {
										if (response?.Success === false) snackbar.showError(t("translation:errorDuringRequest"));

										if (Array.isArray(response?.Lines)) formik.setFieldValue(propertyName, response.Lines);
									})
									.catch(console.error)
									.finally(() => setAddLineLoading(false));
							}}
						>
							{t("translation:addNewLine")}
						</LoadingButtonWithIcon>
					</Grid>
				</Grid>
			)}

			{/* Tableau reprenant les lignes, on utilise pas EnhancedTable car on à besoins d'avoir des champs modifiables */}
			<TableContainer>
				<Table size="small">
					<TableHead>
						<TableRow>
							{headCells
								.filter((cell) => cell?.active ?? true)
								.map((header) => (
									<TableCell key={header.id}>{t(camelCase(header.id))}</TableCell>
								))}

							{/* Bouton de suppression, ajouté en dernière colonne */}
							{!!deleteRowCallback && <TableCell key="delLine">{t("translation:actions")}</TableCell>}
						</TableRow>
					</TableHead>

					<TableBody>
						{(() => {
							let lines = get(formik.values, propertyName);

							// Tableau en cours de chargement
							if (lines === null) {
								return (
									<TableRow>
										{headCells
											.filter((cell) => cell?.active ?? true)
											.map((cell) => (
												<TableCell key={cell.id}>
													<Skeleton variant="rect" />
												</TableCell>
											))}
									</TableRow>
								);
							}
							// Tableau vide
							else if (!Array.isArray(lines) || lines.length === 0) {
								return (
									<TableRow>
										<TableCell colSpan={headCells.filter((cell) => cell?.active ?? true).length + (!!deleteRowCallback ? 1 : 0)} align="center">
											<Typography variant="body1" component="p">
												{t("translation:noData")}
											</Typography>
										</TableCell>
									</TableRow>
								);
							}
							// Tableau avec données
							else {
								return (
									<FormikProvider value={formik}>
										<FieldArray
											name={propertyName}
											render={() =>
												lines.map((line, index) => (
													<TableRow key={line.LineNo}>
														{headCells
															.filter((cell) => cell?.active ?? true)
															.map((cell) => (
																<TableCell key={cell.id}>{cell?.content({ rows: lines, index, row: line })}</TableCell>
															))}

														{/* Bouton de suppression, ajouté en dernière colonne */}
														{!!deleteRowCallback && (
															<TableCell>
																<LoadingIconButton
																	loading={lineNoToDelete === line.LineNo}
																	disabled={deleteRowCallback === null || lineNoToDelete === line.LineNo}
																	icon={<DeleteIcon />}
																	title={t("translation:deleteLine")}
																	onClick={() => {
																		setLineNoToDelete(line.LineNo);
																		deleteRowCallback(line.LineNo)
																			.then((response) => {
																				if (response?.Success === false) snackbar.showSuccess(t("translation:lineRemovedCa"));

																				if (Array.isArray(response?.Lines)) formik.setFieldValue(propertyName, response.Lines);
																			})
																			.catch(console.error)
																			.finally(() => setLineNoToDelete(-1));
																	}}
																/>
															</TableCell>
														)}
													</TableRow>
												))
											}
										/>
									</FormikProvider>
								);
							}
						})()}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}

SubList.propTypes = {
	formik: PropTypes.object.isRequired,
	propertyName: PropTypes.string.isRequired,
	headCells: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			align: PropTypes.oneOf(["center", "inherit", "justify", "left", "right"]),
			active: PropTypes.bool,
			type: PropTypes.oneOf(["string", "bool", "float", "int", "date", "enum", "custom", "delLine"]),
			primary: PropTypes.bool,
			content: (props, propName) => {
				if (props.type === "custom" && typeof props[propName] !== "function")
					return new Error(`if headCell.type = "custom", you need a "content(row, rows)" function to render the value!`);
			},
			searchTerm: PropTypes.func,
		})
	).isRequired,
	addRowCallback: PropTypes.func,
	deleteRowCallback: PropTypes.func,
};

function PropsAreEqual(prev, next) {
	return (
		prev.propertyName === next.propertyName &&
		isEqual(prev.formik?.values?.[prev.propertyName], next.formik?.values?.[next.propertyName]) &&
		isEqual(prev.headCells, next.headCells) &&
		prev?.addRowCallback?.toString() === next?.addRowCallback?.toString() &&
		prev?.deleteRowCallback?.toString() === next?.deleteRowCallback?.toString()
	);
}

export default React.memo(SubList, PropsAreEqual);
