import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { camelCase, isEqual } from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import variants from "../../theme/variants";

function BaseDialog({ open, setOpen, title, actions, ...rest }) {
	const { t } = useTranslation();

	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby={`dialog-title-${camelCase(title)}`} {...rest}>
			<DialogTitle
				id={`dialog-title-${camelCase(title)}`}
				style={{
					backgroundColor: variants[0].palette.primary.main,
					color: variants[0].palette.primary.contrastText,
				}}
			>
				<Grid container justify="space-between" alignItems="center">
					<Typography>{t(title)}</Typography>

					<IconButton onClick={() => setOpen(false)} size="small" color="inherit">
						{" "}
						<CloseIcon />{" "}
					</IconButton>
				</Grid>
			</DialogTitle>

			<DialogContent>{rest?.children}</DialogContent>

			<DialogActions>
				<Button
					variant="contained"
					onClick={() => {
						setOpen(false);
					}}
				>
					{t("translation:close")}
				</Button>

				{actions}
			</DialogActions>
		</Dialog>
	);
}

BaseDialog.propTypes = {
	open: PropTypes.bool.isRequired,
	setOpen: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	actions: PropTypes.node,
};

function PropsAreEqual(prev, next) {
	return (
		prev?.open === next?.open &&
		prev?.setOpen === next?.setOpen &&
		prev?.title === next?.setOpen &&
		isEqual(prev?.children, next?.children) &&
		prev?.actions?.toString() === next?.actions?.toString()
	);
}

export default React.memo(BaseDialog, PropsAreEqual);
