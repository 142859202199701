import { useEffect, useState } from "react";
import { API_URL, getToken, isLoggedIn, removeToken } from "./";

export const fetcher = (signal, method, route, content = null) => {
	const options = {
		signal: signal,
		method: method,
		headers: {
			Accept: "application/json",
		},
		mode: "cors",
	};

	if (isLoggedIn()) {
		options.headers["Authorization"] = `Bearer ${getToken()}`;
	}

	if (content !== null) {
		options.headers["Content-Type"] = "application/json";
		options.body = JSON.stringify(content);
	}

	return fetch(`${API_URL}${route}`, options)
		.then((res) => {
			if (res.status === 401 && isLoggedIn()) return Promise.reject("INVALID_TOKEN");

			if (res.status === 500) return Promise.reject("UNKNOWN_ERROR");

			if ((res.status < 200 || res.status >= 300) && res.status !== 401) return Promise.reject("UNKNOWN_ERROR");

			if (res.status === 204) return res.status;

			if (res.headers.get("Content-Type").indexOf("application/json") !== -1) return res.json();

			return res.status === 200 ? res.text() : res.status;
		})
		.catch((err) => {
			if (err === "INVALID_TOKEN") {
				removeToken();
				window.location = "/";
			} else if (err.name !== "AbortError") {
				let evt = new CustomEvent("api_error", { detail: { status: 500, route: route, method: method } });
				document.dispatchEvent(evt);

				// if (err === "UNKNOWN_ERROR") {
				// 	window.location = "/500";
				// } else if (err.name !== "AbortError") {
				// 	window.location = "/networkerror";
				// }
			}

			return Promise.reject(err);
		});
};

export const useFetch = (method, route, content = null) => {
	const [data, setData] = useState();
	const [refetchLoading, setRefetchLoading] = useState(false);

	const abortController = new AbortController();
	const signal = abortController.signal;

	const refetch = () => {
		setRefetchLoading(true);
		return fetcher(signal, method, route, content)
			.then((res) => {
				setData(res);
			})
			.catch((err) => setData(err))
			.finally(() => setRefetchLoading(false));
	};

	useEffect(() => {
		refetch();

		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return {
		data,
		setData,
		refetchLoading,
		setRefetchLoading,
		refetch,
	};
};

export default useFetch;
