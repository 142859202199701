import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Helmet from "react-helmet";

import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { useTranslation } from "react-i18next";

const Button = styled(MuiButton)(spacing);

const Wrapper = styled.div`
	padding: ${(props) => props.theme.spacing(6)}px;
	text-align: center;
	background: transparent;

	${(props) => props.theme.breakpoints.up("md")} {
		padding: ${(props) => props.theme.spacing(10)}px;
	}
`;

function Page404() {
	const history = useHistory();

	const { t } = useTranslation();

	return (
		<Wrapper>
			<Helmet title={t("translation:notFound")} />
			<Typography component="h1" variant="h1" align="center" gutterBottom>
				404
			</Typography>
			<Typography component="h2" variant="h5" align="center" gutterBottom>
				{t("translation:notFound")}.
			</Typography>
			<Typography component="h2" variant="body1" align="center" gutterBottom>
				{t("translation:notFoundP")}
			</Typography>

			<Button onClick={() => history.goBack()} variant="contained" color="secondary" mt={2}>
				{t("translation:backHome")}
			</Button>
		</Wrapper>
	);
}

export default Page404;
