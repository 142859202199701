import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { camelCase } from "lodash";
import React from "react";
import { useTranslation } from "react-i18next";
import { DEFAULT_MIN_WIDTH_CELL } from "./EnhancedTable";
import "./EnhancedTable.css";

function EnhancedTableHead({ orderDir, orderData, onRequestSort, headCells, headCellsRef, onClickResizeColumn }) {
	// Hooks
	const { t } = useTranslation();

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead className="tableHead">
			<TableRow>
				{headCells
					// On affiche que les colonnes qui ne sont pas spécifiquement caché
					.filter((headCell) => headCell?.active ?? true)
					.map((headCell, colIndex) => {
						// isOrdered n'est vrai que si la colonne actuelle figure dans le tri
						let isOrdered = orderData === headCell.id;
						let headCellDir = orderDir;

						// Si plusieurs colonnes sont triés, il faut trouver la config de la colonne actuelle parmis la liste
						if (Array.isArray(orderData)) {
							const orderCellIndex = orderData.indexOf(headCell.id);

							isOrdered = orderCellIndex !== -1;
							if (isOrdered) headCellDir = orderDir[orderCellIndex];
						}

						return (
							<TableCell
								key={headCell.id}
								ref={headCellsRef[colIndex]}
								align={headCell.alignment}
								padding={headCell.disablePadding ? "none" : "default"}
								sortDirection={isOrdered ? headCellDir : false}
								className="tableCell resizable"
								style={{ width: headCell?.width || `${DEFAULT_MIN_WIDTH_CELL}px` }}
							>
								<TableSortLabel active={isOrdered} direction={isOrdered ? headCellDir : "asc"} onClick={createSortHandler(headCell.id)}>
									{t(camelCase(headCell?.label || headCell.id))}
								</TableSortLabel>
								{/* Affichage de la barre de séparation de la colonne */}
								{/* Au click, on transmet l'index du headCells et la position de la souris */}
								<div onMouseDown={(e) => onClickResizeColumn(colIndex, e.clientX)} className="resizeLine">
									{"|"}
								</div>
							</TableCell>
						);
					})}
			</TableRow>
		</TableHead>
	);
}

export default EnhancedTableHead;
