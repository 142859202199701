//#region Mode développeur
const DEV_MODE = "DEV_MODE";

export const getDevMode = () => {
	return localStorage.getItem(DEV_MODE) === "true";
};

export const setDevMode = (devMode) => {
	localStorage.setItem(DEV_MODE, devMode);
};

export const removeDevMode = () => localStorage.removeItem(DEV_MODE);

document.addEventListener("keydown", (event) => {
	if (event.ctrlKey && event.altKey && event.key.toLowerCase() === "d") {
		let newDevMode = !getDevMode();
		setDevMode(newDevMode);

		// #region Affichage d'un snackbar avec l'état actuel du DevMode
		// ? Oui, c'est moche d'utiliser un event prévu pour les erreurs d'API pour ça,
		// ? mais j'ai la flemme de faire un autre composant qui nous permet d'afficher un snackbar de manière programmatique, merci React...
		let evt = new CustomEvent("api_error", { detail: { status: 200, message: `DevMode ${newDevMode ? "activé" : "désactivé"}` } });
		document.dispatchEvent(evt);
		// #endregion Affichage d'un snackbar avec l'état actuel du DevMode
	}
});
//#endregion

// #region Enregistrement de l'ouverture du menu latérale
const SIDEBAR_OPEN = "SIDEBAR_OPEN";

export const getSidebarOpen = () => {
	let storage = localStorage.getItem(SIDEBAR_OPEN);

	if (storage === null) return true;
	else return storage === "true";
};

export const setSidebarOpen = (sidebarOpen) => {
	localStorage.setItem(SIDEBAR_OPEN, sidebarOpen);
};
// #endregion Enregistrement de l'ouverture du menu latérale
